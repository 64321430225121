import { Badge, Box, Button, Card, Center, Divider, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import { DateRangePicker, Icons, ToolTip, Typography } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageType } from "@reconvert/reconvert-utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  handleDateRangePicker,
  selectAppState,
  toggleAnalyticsView,
  toggleHelpModal,
  toggleWidgetView,
} from "../../../app/slice/appSlice";
import { ShopifyCheckoutEditorPage } from "../../../app/slice/appSlice.model";
import { selectConditionsState } from "../../../app/slice/conditionSlice";
import { selectSlotsState } from "../../../app/slice/slotSlice";
import { APP_PLATFORM } from "../../../config";
import useConditions from "../../../hooks/useConditions";
import { Platform, Variant } from "../../../models";
import EmptyCard from "./EmptyCard";
import { getAllVariantAnalytics } from "../../../app/slice/analyticsSlice";
import useVariants from "../../../hooks/useVariants";
import useUnsavedBar from "../../../hooks/useUnsavedBar";
import PreMadeTemplateModal from "../../PreMadeTemplateModal/PreMadeTemplateModal";
import useSlots from "../../../hooks/useSlots";
import { SlotVariant } from "../../SlotVariant";

const placeSlotLink = {
  [PageType.PRE_PURCHASE_PRODUCT_PAGE]: {
    url: "https://help.reconvert.io/en/article/how-to-place-slots-in-the-shopify-editor-8qknth/reader?bust=1707148053284",
    title: `How to set ReConvert as your pre purchase upsell app | Shopify`,
  },

  [PageType.PRE_PURCHASE_CART_PAGE]: {
    url: "https://help.reconvert.io/en/article/how-to-place-slots-in-the-shopify-editor-8qknth/reader?bust=1707148053284",
    title: `How to set ReConvert as your pre purchase upsell app | Shopify`,
  },

  [PageType.PRE_PURCHASE_CART_DRAWER_PAGE]: {
    url: "https://help.reconvert.io/en/article/how-to-place-slots-in-the-shopify-editor-8qknth/reader?bust=1707148053284",
    title: `How to set ReConvert as your pre purchase upsell app | Shopify`,
  },

  [PageType.THANK_YOU_PAGE]: {
    url: "https://help.reconvert.io/en/article/how-to-place-a-reconvert-slot-on-your-thank-you-page-shopify-1w2k8pg/reader?bust=1716461509072",
    title: `How to place a ReConvert slot on your thank you page | Shopify`,
  },

  [PageType.ORDER_STATUS_PAGE]: {
    url: "https://help.reconvert.io/en/article/how-to-place-a-reconvert-slot-on-your-order-status-page-shopify-dgv5jt/reader?bust=1716461618198",
    title: `How to place a ReConvert slot on your order status page | Shopify`,
  },

  [PageType.CHECKOUT]: {
    url: "https://help.reconvert.io/en/article/how-to-place-a-reconvert-slot-on-your-checkout-shopify-17zqcco/reader?bust=1716461728531",
    title: `How to place a ReConvert slot on your checkout | Shopify`,
  },
  [PageType.POST_PURCHASE_PAGE1]: {
    url: "https://help.reconvert.io/en/article/how-to-set-reconvert-as-your-post-purchase-upsell-app-shopify-1km1dzw/reader?bust=1716461217564",
    title: `How to set ReConvert as your post purchase upsell app | Shopify`,
  },

  [PageType.POST_PURCHASE_PAGE2]: {
    url: "https://help.reconvert.io/en/article/how-to-set-reconvert-as-your-post-purchase-upsell-app-shopify-1km1dzw/reader?bust=1716461217564",
    title: `How to set ReConvert as your post purchase upsell app | Shopify`,
  },
};

const Slots = () => {
  const [showPreMadeTemplateModal, setShowPreMadeTemplateModal] = useState(false);
  const {
    isSidebarCollapased,
    activePage,
    dateRange,
    view: { isWidgetView, isAnalyticView },
    checkoutProfileId,
  } = useAppSelector(selectAppState);
  const { loading: loadingSlots, slots, canCreateSlot, creatingSlot } = useAppSelector(selectSlotsState);
  const { loading, loadingCondition, selectedConditionId } = useAppSelector(selectConditionsState);
  const { deletingVariant, handleDeleteVariant } = useVariants();
  const dispatch = useAppDispatch();
  const { selectedCondition } = useConditions();
  const { preventAction } = useUnsavedBar();

  const { handleCreateSlot } = useSlots();
  const { t } = useTranslation();
  const [isWidth1090] = useMediaQuery("(min-width: 1090px)");
  const [containerWidth, setContainerWidth] = useState<null | number>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedConditionId && isAnalyticView) {
      dispatch(getAllVariantAnalytics(selectedConditionId));
    }
  }, [dispatch, selectedConditionId, dateRange.startDate, dateRange.endDate, isAnalyticView]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef?.current) {
        setContainerWidth(containerRef?.current?.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", () => handleResize());

    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef?.current?.offsetWidth, isSidebarCollapased, deletingVariant, handleDeleteVariant]);

  const measuredRef = useCallback(
    (node: any) => {
      if (node !== null && !containerWidth) {
        setContainerWidth(node.getBoundingClientRect().width);
      }
    },
    [containerRef?.current?.offsetWidth],
  );

  const variantsBySlot = useMemo(() => {
    const groupedVariants: { [key: string]: Variant[] } = {};

    (selectedCondition?.variants || []).forEach((variant: Variant) => {
      const slotId = variant?.slotId;

      if (!groupedVariants[slotId]) {
        groupedVariants[slotId] = [];
      }

      groupedVariants[slotId].push(variant);
    });

    return groupedVariants;
  }, [selectedCondition]);

  const onGoToEditor = useCallback(() => {
    let queryParams = "";

    if (ShopifyCheckoutEditorPage[activePage!]) {
      queryParams = `?page=${ShopifyCheckoutEditorPage[activePage!]}`;
    }

    if (checkoutProfileId) {
      window.open(
        `https://admin.shopify.com/store/${selectedCondition?.platformStoreId?.replaceAll(".myshopify.com", "")}/settings/checkout/editor/profiles/${checkoutProfileId}${queryParams}&context=extensibility&extensionPicker=true`,
      );
    } else {
      window.open(
        `https://${selectedCondition?.platformStoreId}/admin/settings/checkout/editor${queryParams}&context=extensibility&extensionPicker=true`,
        "_blank",
      );
    }
  }, [activePage, selectedCondition?.platformStoreId, checkoutProfileId]);

  const headerTitle: Record<PageType, string> = {
    [PageType.CHECKOUT]: "Checkout slots",
    [PageType.POST_PURCHASE_PAGE1]: "Post purchase - 1st page slots",
    [PageType.POST_PURCHASE_PAGE2]: "Post purchase - 2nd page slots",
    [PageType.THANK_YOU_PAGE]: "Thank you page slots",
    [PageType.ORDER_STATUS_PAGE]: "Order status page slots",
    [PageType.PRE_PURCHASE_PRODUCT_PAGE]: "Product page slots",
    [PageType.PRE_PURCHASE_CART_PAGE]: "Cart page slots",
    [PageType.PRE_PURCHASE_CART_DRAWER_PAGE]: "Cart drawer slots",
  };

  const [shortcut, setShortCut] = useState<string | null>("Last 30 days");
  const [isAnalyticsHovered, setIsAnalyticsHovered] = useState(false);
  const [isWidgetHovered, setIsWidgetHovered] = useState(false);

  const createSlot = useCallback(() => {
    if (APP_PLATFORM === Platform.Shopify) {
      preventAction(() => setShowPreMadeTemplateModal(true));
    } else {
      preventAction(() => handleCreateSlot());
    }
  }, [handleCreateSlot, preventAction]);

  return (
    <Box pt={7} flex={1} pl={4} pr={"24px"}>
      <Flex
        justifyContent={"space-between"}
        alignItems={isSidebarCollapased ? "center" : "self-start"}
        flexDirection="column"
      >
        <Flex w="100%" flexDirection={"column"} gap="2">
          <Flex alignItems={"center"} justifyContent="space-between">
            <Flex alignItems="center">
              <Typography variant="h2-r-medium" pr={2}>
                {t(headerTitle[activePage!])}
              </Typography>
              <ToolTip
                placement="bottom"
                label={t(
                  "Your widgets are stored in 'slots'. Once a condition is met, related slots display on checkout." +
                  (APP_PLATFORM === Platform.Shopify ? " Use the Shopify Editor to position slots on the checkout page." : ''),
                )}
                maxWidth={"285px"}
              >
                <span>
                  <Icons.Question color={"neutrals.700"} />
                </span>
              </ToolTip>
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Flex pr={5} gap="12px">
                {isAnalyticView && (
                  <Box height="36px" background="#ffffff" borderRadius="6px">
                    <DateRangePicker
                      id="date"
                      name="MM / DD"
                      defaultFromDate={new Date(dateRange.startDate)}
                      defaultToDate={new Date(dateRange?.endDate)}
                      onDateValueChange={(startDate: Date | undefined, endDate: Date | undefined) => {
                        if (startDate && endDate) {
                          dispatch(handleDateRangePicker({ startDate: startDate, endDate: endDate }));
                        }
                      }}
                      size="sm"
                      shortcut={shortcut}
                      setShortCut={setShortCut}
                    />
                  </Box>
                )}

                <Flex
                  p="8px"
                  height={"36px"}
                  width={"36px"}
                  bg={isAnalyticView ? "neutrals.300" : "neutrals.200"}
                  borderRadius={4}
                  onClick={() => dispatch(toggleAnalyticsView())}
                  cursor={"pointer"}
                  _hover={{ background: "neutrals.300" }}
                  onMouseEnter={() => setIsAnalyticsHovered(true)}
                  onMouseLeave={() => setIsAnalyticsHovered(false)}
                >
                  <Icons.Analytics
                    height={"20px"}
                    width={"20px"}
                    color={isAnalyticView || isAnalyticsHovered ? "neutrals.700" : "neutrals.500"}
                  />
                </Flex>

                <Flex
                  p="8px"
                  height={"36px"}
                  width={"36px"}
                  bg={isWidgetView ? "neutrals.300" : "neutrals.200"}
                  borderRadius={4}
                  onClick={() => dispatch(toggleWidgetView())}
                  cursor={"pointer"}
                  _hover={{ background: "neutrals.300" }}
                  onMouseEnter={() => setIsWidgetHovered(true)}
                  onMouseLeave={() => setIsWidgetHovered(false)}
                >
                  <Icons.Widgets color={isWidgetView || isWidgetHovered ? "neutrals.700" : "neutrals.500"} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex my="auto">
            {APP_PLATFORM === Platform.Shopify ? (
              <>
                {activePage === PageType.POST_PURCHASE_PAGE1 ||
                activePage === PageType.POST_PURCHASE_PAGE2 ||
                activePage === PageType.PRE_PURCHASE_PRODUCT_PAGE ||
                activePage === PageType.PRE_PURCHASE_CART_PAGE ||
                activePage === PageType.PRE_PURCHASE_CART_DRAWER_PAGE ? (
                  <Typography
                    onClick={() =>
                      dispatch(
                        toggleHelpModal({
                          link: placeSlotLink[activePage]?.url,
                          title: t(placeSlotLink[activePage!]?.title),
                        }),
                      )
                    }
                    variant="small"
                    color="link.color"
                    fontWeight={500}
                    as="a"
                    cursor="pointer"
                  >
                    {activePage === PageType.PRE_PURCHASE_PRODUCT_PAGE ||
                    activePage === PageType.PRE_PURCHASE_CART_PAGE ||
                    activePage === PageType.PRE_PURCHASE_CART_DRAWER_PAGE
                      ? t("Learn more about pre purchase pages")
                      : t("Learn more about post purchase pages")}
                  </Typography>
                ) : (
                  <>
                    <Flex>
                      <Typography
                        variant="small"
                        color="link.color"
                        fontWeight={500}
                        as="a"
                        pr={"16px"}
                        cursor="pointer"
                        onClick={() =>
                          dispatch(
                            toggleHelpModal({
                              link: placeSlotLink[activePage!]?.url,
                              title: t(placeSlotLink[activePage!]?.title),
                            }),
                          )
                        }
                      >
                        {t("How to place a slot?")}
                      </Typography>
                      <Flex justifyContent={"space-between"} alignItems={"center"}>
                        <Typography
                          variant="small"
                          as="a"
                          cursor="pointer"
                          color="link.color"
                          pr={2}
                          fontWeight={500}
                          onClick={onGoToEditor}
                        >
                          {t("Place your slots in the Shopify editor")}
                        </Typography>
                        <Icons.ExternalLink
                          cursor="pointer"
                          onClick={onGoToEditor}
                          className="externalLink"
                          w={4}
                          h={4}
                        />
                      </Flex>
                    </Flex>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Divider
        ml="5px"
        orientation="horizontal"
        my="16px"
        width={containerWidth ? containerWidth : "100%"}
        maxWidth={"748px"}
      />
      {canCreateSlot && (
        <Button
          variant="secondary"
          width={containerWidth ? containerWidth : "100%"}
          mb="16px"
          onClick={createSlot}
          isDisabled={!selectedCondition}
          isLoading={creatingSlot}
          color="neutrals.900"
          padding={"0px 5px 0px 5px"}
          maxWidth={"748px"}
          ml="5px"
        >
          <Typography variant="subheading2" fontWeight={500} lineHeight="20px" mr="8px">
            {t("Create a slot")}
          </Typography>
          <Icons.PlusCircle cursor="pointer" height={"16px"} width={"16px"} />
        </Button>
      )}
      <Box
        overflowY={"auto"}
        height={`calc(100vh - ${canCreateSlot ? "190px" : `${APP_PLATFORM === Platform.Shopify ? "120px" : "205px"}`} )`}
        padding={"0px 5px 0px 5px"}
      >
        {loadingSlots || loading || loadingCondition ? (
          <Center>
            <Spinner variant={"ring"} size={"lg"} mt={20} />
          </Center>
        ) : (
          <>
            {!selectedCondition ? (
              <EmptyCard />
            ) : (
              <Box ref={containerRef}>
                <Card
                  pb={4}
                  background="rgba(255, 255, 255, 0.50)"
                  borderTop={"1px solid"}
                  borderTopColor={"neutrals.400"}
                  borderTopRadius={"8px"}
                  height="auto"
                  minHeight={`calc(100vh - ${isWidth1090 ? "126px" : "80px"})`}
                  maxWidth={"748px"}
                  ref={measuredRef}
                >
                  <Box ml={"-5px"} mt={"8px"}>
                    <Badge colorScheme="secondary.600" size="large" variant="sharp" maxWidth={"60%"}>
                      <Typography
                        variant="subheading2"
                        style={{ zIndex: "2", overflow: "hidden", textOverflow: "ellipsis" }}
                        textTransform="uppercase"
                      >
                        {selectedCondition?.name}
                      </Typography>
                    </Badge>
                  </Box>

                  {slots && slots.length > 0 ? (
                    <>
                      {slots.map((slot: any, index: number) => {
                        return <SlotVariant slot={slot} variants={variantsBySlot[`${slot?.id}`]} key={index} />;
                      })}
                      {canCreateSlot && <Divider orientation="horizontal" mt={"32px"} />}
                      {canCreateSlot && (
                        <Flex justifyContent="center" alignItems="center" mt={6} gap={2} color="neutrals.700">
                          <Button
                            size="sm"
                            variant="outline"
                            padding={"4px 8px"}
                            height={"min-content"}
                            color="neutrals.700"
                            border={"1px solid"}
                            borderColor={"neutrals.700"}
                            _hover={{
                              color: "neutrals.900",
                              border: "1px solid",
                              borderColor: "neutrals.900",
                              bg: "neutrals.200",
                            }}
                            _active={{ color: "#ffffff", bg: "neutrals.900" }}
                            gap={1}
                            onClick={createSlot}
                            display={"flex"}
                            isDisabled={!selectedCondition}
                            isLoading={creatingSlot}
                            _loading={{
                              height: "25px",
                            }}
                          >
                            <Typography variant="xsmall">{t("Create a slot")}</Typography>
                            <Icons.PlusCircle height={"12px"} width={"12px"} />
                          </Button>
                        </Flex>
                      )}
                    </>
                  ) : (
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
                      <Typography variant="subheading1" color="neutrals.600">
                        {t("Please create a slot")}
                      </Typography>
                      <Button
                        onClick={createSlot}
                        px={4}
                        mt={4}
                        isLoading={creatingSlot}
                        rightIcon={<Icons.PlusCircle />}
                      >
                        {t("Create a slot")}
                      </Button>
                    </Flex>
                  )}
                </Card>
              </Box>
            )}
          </>
        )}
      </Box>
      {APP_PLATFORM === Platform.Shopify && (
        <PreMadeTemplateModal
          showPreMadeTemplateModal={showPreMadeTemplateModal}
          onClose={() => setShowPreMadeTemplateModal(false)}
          slotNo={null}
          setShowPreMadeTemplateModal={setShowPreMadeTemplateModal}
        />
      )}
    </Box>
  );
};

export default Slots;
